import React, { Suspense, lazy, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate,useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import PageNotFound from './ui-components/shared/PageNotFound';
import './App.css';
import axios from 'axios';
import globalVariables from "./Global.js";
import { useNavigate } from 'react-router-dom';
// Import components using lazy loading
const MasterLayout = lazy(() => import('./ui-components/MasterLayout'));
const Dashboard = lazy(() => import('./ui-components/Dashboard'));
const ArchNews = lazy(() => import('./ui-components/news/ArchNews'));
const ArchNewsSingle = lazy(() => import('./ui-components/news/ArchNewsSingle'));
const ParishReport = lazy(() => import('./ui-components/ParishReport'));
const PriestReport = lazy(() => import('./ui-components/PriestReport'));
const EstablishmentReport = lazy(() => import('./ui-components/EstablishmentReport'));
const FilialChurchReport = lazy(() => import('./ui-components/FilialChurchReport'));
const InstitutionsReport = lazy(() => import('./ui-components/InstitutionsReport'));
const ShrinesReport = lazy(() => import('./ui-components/ShrinesReport'));
const SundaySchoolReport = lazy(() => import('./ui-components/SundaySchoolReport'));
const OrganizationReport = lazy(() => import('./ui-components/OrganizationReport'));
const ServiceTeamReport = lazy(() => import('./ui-components/ServiceTeamReport'));
const PaginatedDataTable = lazy(() => import('./ui-components/PaginatedDataTable'));
const PDFGenerator = lazy(() => import('./ui-components/PDFGenerator'));
const ExcelGenerator = lazy(() => import('./ui-components/ExcelGenerator'));
const Home = lazy(() => import('./ui-components/Home'));
const Churches = lazy(() => import('./ui-components/Churches'));
const Priests = lazy(() => import('./ui-components/Priests'));
const EmployeeAtCall = lazy(() => import('./ui-components/EmployeeAtCall'));
const ShopsAtCall = lazy(() => import('./ui-components/ShopsAtCall'));
const HealthcareAtCall = lazy(() => import('./ui-components/HealthcareAtCall'));
const ParishDetails = lazy(() => import('./ui-components/ParishDetails'));
const ParishContacts = lazy(() => import('./ui-components/ParishContacts'));
const ConventsReport = lazy(() => import('./ui-components/ConventsReport'));
const SundayschoolMembersReport = lazy(() => import('./ui-components/SundayschoolMembersReport'));
const SeminariansReport = lazy(() => import('./ui-components/SeminariansReport'));
const SistersReport = lazy(() => import('./ui-components/SistersReport'));
const InstitutionMembersReport = lazy(() => import('./ui-components/InstitutionMembersReport'));
const OrganizationMembersReport = lazy(() => import('./ui-components/OrganizationMembersReport'));
const KoottayamaLeadersReport = lazy(() => import('./ui-components/KoottayamaLeadersReport'));
const FamilyReport = lazy(() => import('./ui-components/FamilyReport'));
const MemberReport = lazy(() => import('./ui-components/MemberReport'));
const FamilyModalForm = lazy(() => import('./ui-components/FamilyModalForm'));

const AspirantsReport = lazy(() => import('./ui-components/AspirantsReport'));
const VocationRegisterReport = lazy(() => import('./ui-components/VocationRegisterReport'));
const AbadonedFaithReport = lazy(() => import('./ui-components/AbadonedFaithReport'));
const LiturgicalCalender = lazy(() => import('./ui-components/LiturgicalCalender'));
const PrayerHeaders = lazy(() => import('./ui-components/PrayerHeaders'));
const SinglePrayer = lazy(() => import('./ui-components/SinglePrayer'));
const CountryDropdown = lazy(() => import('./ui-components/CountryDropdown'));
const ModalExample = lazy(() => import('./ui-components/ModalExample'));
const ParishImage = lazy(() => import('./ui-components/ParishImage'));
const ParishSummary = lazy(() => import('./ui-components/ParishSummary'));
const ParishregisteryMenu = lazy(() => import('./ui-components/ParishregisteryMenu'));
const AccountsMenu = lazy(() => import('./ui-components/AccountsMenu'));
const SurveyParticipationSummary = lazy(() => import('./ui-components/SurveyParticipationSummary'));
const SingleNews = lazy(() => import('./ui-components/SingleNews'));
const ParishAssetSummary = lazy(() => import('./ui-components/accounts/ParishAssetSummary'));
const ParishLiabilitySummary = lazy(() => import('./ui-components/accounts/ParishLiabilitySummary'));
const ChrismationReport = lazy(() => import('./ui-components/ChrismationReport'));
const DeathReport = lazy(() => import('./ui-components/DeathReport'));
const BetrothalReport = lazy(() => import('./ui-components/BetrothalReport'));
const FirstholycommunionReport = lazy(() => import('./ui-components/FirstholycommunionReport'));
const MarriageReport = lazy(() => import('./ui-components/MarriageReport'));
const PravasiReport = lazy(() => import('./ui-components/PravasiReport'));
const SolemncommunionReport = lazy(() => import('./ui-components/SolemncommunionReport'));
const BaptismReport = lazy(() => import('./ui-components/BaptismReport'));
const ParishSingleNews = lazy(() => import('./ui-components/ParishSingleNews'));
const ParishMessages = lazy(() => import('./ui-components/ParishMessages'));
const ParishMessageDetails = lazy(() => import('./ui-components/ParishMessageDetails'));

const NoDataFound = lazy(() => import('./ui-components/NoDataFound'));
const ChartSummary = lazy(() => import('./ui-components/ChartSummary'));
const Login = lazy(() => import('./Login'));

function App() {
 
  // Assume isAuthenticated is set based on your authentication logic
  const [isAuthenticated, setIsAuthenticated] = useState(false);
 

  const verifyServerToken = () => {
    setIsAuthenticated(true);
    return new Promise((resolve, reject) => {
      try {
        const storedAuthData = localStorage.getItem('response_data');
        if (storedAuthData) {
          const authData = JSON.parse(storedAuthData);
  
          const form_data = {
            token: authData.token,
            user_id: authData.userid,
          };
  
          axios.post(globalVariables.mobile_api + '/Curia_api/isTokenAuthorized', form_data, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          })
          .then(response => {
            const response_data = response.data;
            
            resolve(response_data);
          
          })
          .catch(error => {
            console.error('Error verifying server token:', error);
            reject(error);
          });
        }
      } catch (error) {
        console.error('Error verifying server token:', error);
        reject(error);
      }
    });
  };
  
  useEffect(() => {   
   
    verifyServerToken()
      .then(response_data => {
       
        setIsAuthenticated(true);
        localStorage.setItem('response_data', JSON.stringify(response_data));
        localStorage.setItem('username', response_data.username);
        axios.defaults.headers.common['Authorization'] = `Bearer ${response_data.token} ${response_data.userid}`;
       
      })
      .catch(error => {
        console.log("else");
        setIsAuthenticated(false);
        localStorage.removeItem('lastVisitedPage');
        console.error('Error in verifyServerToken:', error);
      });
     
}, []);

  return (
    <BrowserRouter>
    
      <Routes>

        <Route
            path="/"
            element={isAuthenticated ? <MasterLayout  /> : <Navigate to="/login" />}
          >
       
          <Route index element={<Dashboard />} />
          <Route path="news" element={<ArchNews />} />
          <Route path="parishreport" element={<ParishReport />} />
          <Route path="priestreport" element={<PriestReport />} />
          <Route path="establishmentreport" element={<EstablishmentReport />} />
          <Route path="filialchurchreport" element={<FilialChurchReport />} />
          <Route path="shrinesreport" element={<ShrinesReport />} />
          <Route path="institutionsreport" element={<InstitutionsReport />} />
          <Route path="serviceteamreport" element={<ServiceTeamReport />} />
          <Route path="sundayschoolreport" element={<SundaySchoolReport />} />
          <Route path="organizationreport" element={<OrganizationReport />} />
          <Route path="conventsreport" element={<ConventsReport />} />
          <Route path="sundayschoolmembersreport" element={<SundayschoolMembersReport />} />
          <Route path="seminariansreport" element={<SeminariansReport />} />
          <Route path="aspirantsreport" element={<AspirantsReport />} />
          <Route path="abadonedfaithreport" element={<AbadonedFaithReport />} />
          <Route path="vocationregisterreport" element={<VocationRegisterReport />} />
          <Route path="sistersreport" element={<SistersReport />} />
          <Route path="institutionmembersreport" element={<InstitutionMembersReport />} />
          <Route path="organizationmembersreport" element={<OrganizationMembersReport />} />
          <Route path="koottayamaleadersreport" element={<KoottayamaLeadersReport />} />
          <Route path="familyreport" element={<FamilyReport />} />
          <Route path="memberreport" element={<MemberReport />} />
          <Route path="familymodalform" element={<FamilyModalForm />} />
          <Route path="parishimage" element={<ParishImage />} />
          <Route path="parishsummary" element={<ParishSummary />} />
          <Route path="surveyparticipationsummary" element={<SurveyParticipationSummary />} />
          <Route path="parishmessages" element={<ParishMessages />} />
          <Route path="parishmessagedetails/:mailbox_id" element={<ParishMessageDetails />} />
          
          <Route path="home" element={<Home />} />
          <Route path="churches" element={<Churches />} />
          <Route path="priests" element={<Priests />} />
          <Route path="employeeatcall" element={<EmployeeAtCall />} />
          <Route path="shopsatcall" element={<ShopsAtCall />} />
          <Route path="healthcareatcall" element={<HealthcareAtCall />} />
          <Route path="parishdetails" element={<ParishDetails />} />
          <Route path="parishcontacts" element={<ParishContacts />} />
          <Route path="countrydropdown" element={<CountryDropdown />} />
          <Route path="liturgicalcalender" element={<LiturgicalCalender />} />
          <Route path="prayerheaders" element={<PrayerHeaders />} />
          <Route path="singleprayer" element={<SinglePrayer />} />

          <Route path="paginateddatatable" element={<PaginatedDataTable />} />
          <Route path="PDFGenerator" element={<PDFGenerator />} />
          <Route path="ExcelGenerator" element={<ExcelGenerator />} />
          
          
          <Route path="add-news" element={<ArchNewsSingle />} />
          <Route path="accountsmenu" element={<AccountsMenu />} />
          <Route path="parishregisterymenu" element={<ParishregisteryMenu />} />
         
          <Route path="singlenews" element={<SingleNews />} />
          <Route path="parishassetsummary" element={<ParishAssetSummary />} />
          <Route path="parishliabilitysummary" element={<ParishLiabilitySummary />} />
          
          <Route path="baptismreport" element={<BaptismReport />} />
          <Route path="solemncommunionreport" element={<SolemncommunionReport />} />
          <Route path="marriagereport" element={<MarriageReport />} />
          <Route path="firstholycommunionreport" element={<FirstholycommunionReport />} />
          <Route path="betrothalreport" element={<BetrothalReport />} />
          <Route path="pravasireport" element={<PravasiReport />} />
          
          <Route path="deathreport" element={<DeathReport />} />
          <Route path="parishsinglenews" element={<ParishSingleNews />} />
          <Route path="chrismationreport" element={<ChrismationReport />} />
          <Route path="nodatafound" element={<NoDataFound />} />
          <Route path="chartsummary" element={<ChartSummary />} />
        </Route>

        <Route element={<Login/>} path="/login"/>
        <Route path="*" element={<PageNotFound />} />
      
      </Routes>
     
    
  </BrowserRouter>
  );
}

export default App;